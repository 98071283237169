.bmi-form input,
.bmi-form select,
.cal-btn {
  box-sizing: border-box;
  background-color: none;
  border-bottom: 1px solid black;
  width: 20vw;
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: black;
  font-size: 14px;
  outline: none;
  border-radius: 5px;
}
.cal-btn:hover {
  transition: all ease 0.2s;
  transform: translateY(-4px);
}
.bmi-form input:focus {
  background-color: transparent;
}
.bmi-form select {
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  /* padding: 12px; */
  border-radius: 5px;
}
.bmi-form .cal-btn {
  border: none;
  padding: 15px 30px;
  background-color: #21695c;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
}
.bmi-result {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  width: 80vw;
  height: 80vh;
  top: 10rem;
  padding: 5vh 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
}
.bmi-result button {
  position: absolute;
  top: 3rem;
  left: 3rem;
  z-index: 11;
  border: 1px solid #21695c;
  background-color: transparent;
  border-radius: 25px;
  padding: 10px 20px;
}
.bmiResult {
  padding-top: 3rem;
  font-size: 1.5rem;
}
.bmi-status {
  font-size: 30px;
  font-weight: 100;
}
.bmi-status span {
  color: #21695c;
}
.bmi-status div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.bmi-status-icon {
  width: 10rem;
}
.bmi-status-div {
  border-bottom: 1px solid rgb(218, 215, 215);
}
.bmi-index {
  display: flex;
  background: linear-gradient(
    90deg,
    rgba(227, 182, 182, 0.7949386297487745) 0%,
    rgba(255, 112, 0, 1) 100%
  );
  padding: 10px 20px;
  border-radius: 25px;
  margin-top: 5%;
  text-align: center;
}

.bmi-index div:not(:last-child) {
  border-right: 1px solid #fff;
}
.bmi-index div {
  padding: 10px 20px;
}

.bmi-index div p {
  margin: 0;
  padding-top: 6px;
  font-size: 18px;
  font-weight: 600;
}
.underweight::after,
.healthy::after,
.overweight::after,
.obese::after {
  content: "";
  position: absolute;
  bottom: 20vh;

  width: 2px; /* Adjust the width of the vertical line */
  height: 100px;
  background-color: #000; /* Color of the vertical line */
}

@media only screen and (max-width: 658px) {
  #bmi {
    padding: 6vh 8vw;
    text-align: center;
  }
  .bmi-form {
    flex-direction: column;
    width: 90%;
    gap: 10px;
    padding: 40px 10px;
    text-align: left;
  }
  .bmi-form div {
    width: 100%;
    padding: 14px 10px;
  }
  .bmi-form div input,
  .bmi-form select,
  .bmi-form button {
    width: 100%;
  }
  .bmi-result {
    text-align: center;
    width: 100%;
  }

  .bmi-result {
    width: 90vw;
    height: 70vh;
    top: 25rem;
    padding: vh 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
    text-wrap: wrap;
  }
  .bmi-result button {
    top: 2rem;
    left: 2rem;
    padding: 5px 10px;
    font-size: 14px;
  }
  .bmiResult {
    padding-top: 1rem;
    font-size: 1.2rem;
  }
  .bmi-status {
    font-size: 1.5rem;
  }
  .bmi-status div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .bmi-status-icon {
    width: 10rem;
  }
  .bmi-status-div {
    border-bottom: 1px solid rgb(218, 215, 215);
  }
  .bmi-index {
    display: none;
  }
  .gender-categories {
    gap: 8px;
  }
  .gender-categories div {
    width: 10rem;
    font-size: 1rem;
    padding: 10px;
    gap: 6px;
  }
  .gender-categories div span {
    font-size: 1rem;
  }
  .gender-categories div p {
    margin: 0;
  }
}

@media (max-width: 576px) {
  .bmi-status {
    font-size: 1.3rem;
  }
  .bmi-result {
    height: 60vh;
  }
  .gender-categories {
    gap: 8px;
  }
  .gender-categories div {
    width: 10rem;
    font-size: 1rem;
    padding: 10px;
    gap: 6px;
  }
  .gender-categories div span {
    font-size: 1rem;
  }
  .gender-categories div p {
    margin: 0;
  }
}

@media (max-width: 480px) {
  .bmi-status {
    font-size: 1.3rem;
  }
  .bmi-result {
    top: 75vh;
    height: 60vh;
  }
  .gender-categories {
    gap: 8px;
  }
  .gender-categories div {
    width: 4rem;
    font-size: 0.8rem;
    padding: 10px;
    gap: 6px;
  }
  .gender-categories div span {
    font-size: 0.8rem;
  }
  .gender-categories div p {
    margin: 0;
  }
}
