@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://fonts.cdnfonts.com/css/satoshi");
* {
  scroll-behavior: smooth;
  /* font-family: "Poppins", sans-serif; */
}
html,
body {
  overflow-x: hidden;
}
.bg-home {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    260deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(227, 238, 249, 1) 97%
  );
  /* background-color: #EFEFEF; */
    /* background: linear-gradient(135deg, #F9F9F9, #E8F9A9, #B2F0E9); */
}
.text-heading {
  color: #333333;
}
.text-secondary {
  color: #717171;
}
@keyframes fadeKeyframe {
  from {
    transform: translateY(-5px);
    /* opacity: 0.5; */
  }
  to {
    transform: translate(0);
    /* opacity: 1; */
  }
}

.fadeanime {
  animation: fadeKeyframe 300ms ease;
}
.dash-bg {
  /* background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%); */
  /* background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%); */
  background-image: linear-gradient(to top, #ebc0fd 0%, #d9ded8 100%);
}
.dash-component-bg {
  background-image: linear-gradient(to top, #cd9cf2 0%, #f6f3ff 100%);
}

@media (max-width: 767.98px) {
  .custom-px {
    padding: 1rem; /* Adjust padding as needed for smaller screens */
  }
  .blogpage-px {
    padding-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}

Link {
  text-decoration: none;
  color: black;
}

.two-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; /* Adjust based on your font size and line height */
  line-height: 1.5em; /* Adjust based on your desired line spacing */
}
.image-container {
  position: relative;
  display: inline-block;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5%; /* Adjust this to control how much of the image the fade effect covers */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  pointer-events: none; /* Ensure the overlay doesn't interfere with interactions */
  /* border-radius: ; */
}
.shadow-custom{
filter: drop-shadow(5px 21px 30px #bababa);
}

/* -------------------plans card crousel------------------------ */

.carousel-container {
  display: none;
  width: 100%;
  overflow: hidden;
  /* position: relative; */
}

.carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-slide {
  min-width: 100%;
  box-sizing: border-box;
}

.carousel-indicators {
  /* position: absolute; */
  bottom: 1rem;
  left: 30%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.carousel-indicator {
  
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.carousel-indicator.active {
  background-color: #333;
}

@media (max-width: 1024px) {
  .grid-container {
    display: none;
  }
  .carousel-container {
    display: block;
  }
}

@media (min-width: 1024px) {
  .grid-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    /* gap: 20px; */
  }
  .carousel-container {
    display: none;
  }
}

.page-padding{
  padding-left: 8rem;
  padding-right: 8rem;
}
@media (max-width: 768px) {
  .page-padding {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 576px) {
  .page-padding {
    padding-left: 16px;
    padding-right: 16px;
  }
}